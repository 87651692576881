import db from '../../db.json';
import { Logo } from '../Logo/Logo';

export const LinkTree = () => {
    return (
        <div className="flex flex-col items-center p-2 min-h-screen">
            <Logo />
            {
                db.linkTrees.map(link => link.isActive &&
                    (
                        <a 
                            className="text-xl mt-2 border w-full text-center p-2 max-w-[968px] cursor-pointer 
                                hover:bg-charcoal hover:text-white active:bg-charcoal active:text-white"
                            href={link.url}
                            key={link.id}
                        >
                            <span>{link.text}</span>
                        </a>
                    )
                )
            }
        </div>
    )
}